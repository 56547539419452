import React from 'react';
import './sideBar.css'; // File CSS cho Sidebar
import { prizes } from '../config/data';

const Sidebar = ({ currentQuestionIndex }) => {
    const reversedPrizes = prizes.slice().reverse();

    return (
        <div className="sidebar">
            <ul className="prizes-list">
                {reversedPrizes.map((prize, index) => (
                    <li
                        key={index}
                        className={currentQuestionIndex === (prizes.length - 1 - index) ? 'active' : ''}>
                        {prize.question}
                        <span className="diamond-icon"></span>
                        {prize.value}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
