const questions = [
    {
        id: 1,
        question: "Thủ đô của Nhật Bản là gì?",
        options: ["Bắc Kinh", "Seoul", "Tokyo", "Bangkok"],
        correctAnswer: "Tokyo"
    },
    {
        id: 2,
        question: "Nguyên tố nào có ký hiệu hóa học là O?",
        options: ["Oxy", "Vàng", "Sắt", "Cacbon"],
        correctAnswer: "Oxy"
    },
    {
        id: 3,
        question: "Ai là tác giả của 'Romeo và Juliet'?",
        options: ["Charles Dickens", "William Shakespeare", "Mark Twain", "Ernest Hemingway"],
        correctAnswer: "William Shakespeare"
    },
    {
        id: 4,
        question: "Hành tinh nào được gọi là hành tinh Đỏ?",
        options: ["Trái Đất", "Sao Hỏa", "Sao Mộc", "Sao Kim"],
        correctAnswer: "Sao Hỏa"
    },
    {
        id: 5,
        question: "Chiến tranh thế giới thứ hai kết thúc vào năm nào?",
        options: ["1945", "1939", "1941", "1948"],
        correctAnswer: "1945"
    },
    {
        id: 6,
        question: "Ai là người vẽ bức tranh Mona Lisa?",
        options: ["Vincent van Gogh", "Claude Monet", "Pablo Picasso", "Leonardo da Vinci"],
        correctAnswer: "Leonardo da Vinci"
    },
    {
        id: 7,
        question: "Chất tự nhiên cứng nhất trên Trái Đất là gì?",
        options: ["Vàng", "Sắt", "Kim cương", "Bạc"],
        correctAnswer: "Kim cương"
    },
    {
        id: 8,
        question: "Quốc gia nào nổi tiếng với kim tự tháp?",
        options: ["Mexico", "Ai Cập", "Ấn Độ", "Peru"],
        correctAnswer: "Ai Cập"
    },
    {
        id: 9,
        question: "Cơ quan nào trong cơ thể con người có chức năng bơm máu?",
        options: ["Não", "Gan", "Phổi", "Tim"],
        correctAnswer: "Tim"
    },
    {
        id: 10,
        question: "Ai là CEO hiện tại của Tesla?",
        options: ["Bill Gates", "Elon Musk", "Steve Jobs", "Jeff Bezos"],
        correctAnswer: "Elon Musk"
    },
    {
        id: 11,
        question: "Châu lục nào có diện tích lớn nhất?",
        options: ["Châu Phi", "Châu Á", "Châu Âu", "Nam Mỹ"],
        correctAnswer: "Châu Á"
    },
    {
        id: 12,
        question: "Điểm sôi của nước ở nhiệt độ Celsius là bao nhiêu?",
        options: ["50°C", "100°C", "150°C", "200°C"],
        correctAnswer: "100°C"
    },
    {
        id: 13,
        question: "Ngôn ngữ chính thức của Brazil là gì?",
        options: ["Tiếng Tây Ban Nha", "Tiếng Bồ Đào Nha", "Tiếng Anh", "Tiếng Pháp"],
        correctAnswer: "Tiếng Bồ Đào Nha"
    },
    {
        id: 14,
        question: "Trong môn thể thao nào người ta sử dụng thuật ngữ 'love' để chỉ điểm số 0?",
        options: ["Bóng rổ", "Bóng đá", "Quần vợt", "Bóng chày"],
        correctAnswer: "Quần vợt"
    },
    {
        id: 15,
        question: "Hành tinh nào gần Mặt Trời nhất?",
        options: ["Sao Kim", "Trái Đất", "Sao Thủy", "Sao Hỏa"],
        correctAnswer: "Sao Thủy"
    },
    {
        id: 16,
        question: "Nước nào có diện tích lớn nhất thế giới?",
        options: ["Trung Quốc", "Hoa Kỳ", "Canada", "Nga"],
        correctAnswer: "Nga"
    },
    {
        id: 17,
        question: "Nguyên tố hóa học nào có ký hiệu là H?",
        options: ["Helium", "Hydrogen", "Bạc", "Vàng"],
        correctAnswer: "Hydrogen"
    },
    {
        id: 18,
        question: "Quốc gia nào là quê hương của pizza?",
        options: ["Pháp", "Ý", "Hy Lạp", "Mexico"],
        correctAnswer: "Ý"
    },
    {
        id: 19,
        question: "Cầu thủ nào đã giành nhiều Quả bóng vàng nhất?",
        options: ["Cristiano Ronaldo", "Pele", "Lionel Messi", "Diego Maradona"],
        correctAnswer: "Lionel Messi"
    },
    {
        id: 20,
        question: "Thủ đô của Pháp là gì?",
        options: ["Rome", "Madrid", "Paris", "Berlin"],
        correctAnswer: "Paris"
    },
    {
        id: 21,
        question: "Đơn vị đo lường cường độ dòng điện là gì?",
        options: ["Volt", "Watt", "Ohm", "Ampe"],
        correctAnswer: "Ampe"
    },
    {
        id: 22,
        question: "Ai đã phát minh ra bóng đèn?",
        options: ["Isaac Newton", "Albert Einstein", "Nikola Tesla", "Thomas Edison"],
        correctAnswer: "Thomas Edison"
    },
    {
        id: 23,
        question: "Bức tranh 'Đêm đầy sao' do ai vẽ?",
        options: ["Pablo Picasso", "Leonardo da Vinci", "Vincent van Gogh", "Michelangelo"],
        correctAnswer: "Vincent van Gogh"
    },
    {
        id: 24,
        question: "Đơn vị nào dùng để đo tần số?",
        options: ["Hertz", "Joule", "Kelvin", "Pascal"],
        correctAnswer: "Hertz"
    },
    {
        id: 25,
        question: "Kim loại nào có độ dẫn điện tốt nhất?",
        options: ["Vàng", "Bạc", "Đồng", "Sắt"],
        correctAnswer: "Bạc"
    },
    {
        id: 26,
        question: "Quốc gia nào có dân số lớn nhất thế giới?",
        options: ["Ấn Độ", "Hoa Kỳ", "Trung Quốc", "Nga"],
        correctAnswer: "Trung Quốc"
    },
    {
        id: 27,
        question: "Loài chim nào không thể bay?",
        options: ["Cú", "Chim cánh cụt", "Chim én", "Chim sẻ"],
        correctAnswer: "Chim cánh cụt"
    },
    {
        id: 28,
        question: "Đỉnh núi cao nhất thế giới là gì?",
        options: ["K2", "Everest", "Denali", "Kangchenjunga"],
        correctAnswer: "Everest"
    },
    {
        id: 29,
        question: "Ngôi sao gần Trái Đất nhất là gì?",
        options: ["Sirius", "Alpha Centauri", "Mặt Trời", "Proxima Centauri"],
        correctAnswer: "Mặt Trời"
    },
    {
        id: 30,
        question: "Biển nào lớn nhất thế giới?",
        options: ["Biển Đỏ", "Biển Địa Trung Hải", "Biển Caspi", "Biển Đông"],
        correctAnswer: "Biển Caspi"
    },
    {
        id: 31,
        question: "Loài vật nào có trí nhớ lâu nhất?",
        options: ["Voi", "Cá heo", "Chó", "Khỉ"],
        correctAnswer: "Voi"
    },
    {
        id: 32,
        question: "Quốc gia nào là nơi khai sinh của môn bóng đá hiện đại?",
        options: ["Brazil", "Argentina", "Anh", "Pháp"],
        correctAnswer: "Anh"
    },
    {
        id: 33,
        question: "Hóa thạch động vật nào được biết đến là lớn nhất trên thế giới?",
        options: ["Khủng long bạo chúa", "Cá voi xanh", "Thằn lằn cổ đại", "Cá mập Megalodon"],
        correctAnswer: "Cá voi xanh"
    },
    {
        id: 34,
        question: "Người nào đã phát minh ra lý thuyết tương đối?",
        options: ["Isaac Newton", "Galileo Galilei", "Albert Einstein", "Nikola Tesla"],
        correctAnswer: "Albert Einstein"
    },
    {
        id: 35,
        question: "Nguyên tố nào nhẹ nhất trong bảng tuần hoàn hóa học?",
        options: ["Helium", "Lithium", "Hydrogen", "Beryllium"],
        correctAnswer: "Hydrogen"
    },
    {
        id: 36,
        question: "Chất khí nào chiếm 78% khí quyển Trái Đất?",
        options: ["Oxy", "Nitơ", "Cacbon dioxide", "Hydro"],
        correctAnswer: "Nitơ"
    },
    {
        id: 37,
        question: "Cấu trúc nào là cấu trúc nhân tạo duy nhất có thể nhìn thấy từ không gian?",
        options: ["Tháp Eiffel", "Kim tự tháp Giza", "Vạn Lý Trường Thành", "Đấu trường La Mã"],
        correctAnswer: "Vạn Lý Trường Thành"
    },
    {
        id: 38,
        question: "Tổng thống Mỹ đầu tiên là ai?",
        options: ["Abraham Lincoln", "George Washington", "John Adams", "Thomas Jefferson"],
        correctAnswer: "George Washington"
    },
    {
        id: 39,
        question: "Quốc gia nào có hình dáng giống một chiếc ủng?",
        options: ["Italy", "Tây Ban Nha", "Hy Lạp", "Pháp"],
        correctAnswer: "Italy"
    },
    {
        id: 40,
        question: "Người chiến thắng giải Nobel Hòa bình đầu tiên là ai?",
        options: ["Nelson Mandela", "Theodore Roosevelt", "Marie Curie", "Henry Dunant"],
        correctAnswer: "Henry Dunant"
    },
    {
        id: 41,
        question: "Môn thể thao nào phổ biến nhất trên thế giới?",
        options: ["Bóng rổ", "Quần vợt", "Bóng đá", "Bóng chày"],
        correctAnswer: "Bóng đá"
    },
    {
        id: 42,
        question: "Loại trái cây nào có hàm lượng vitamin C cao nhất?",
        options: ["Táo", "Cam", "Ổi", "Chuối"],
        correctAnswer: "Ổi"
    },
    {
        id: 43,
        question: "Kinh thành Huế được xây dựng vào thời vua nào?",
        options: ["Vua Gia Long", "Vua Minh Mạng", "Vua Bảo Đại", "Vua Quang Trung"],
        correctAnswer: "Vua Gia Long"
    },
    {
        id: 44,
        question: "Thành phố nào được gọi là 'Thành phố Tình yêu'?",
        options: ["Rome", "Venice", "Paris", "New York"],
        correctAnswer: "Paris"
    },
    {
        id: 45,
        question: "Hệ thống vận tải ngầm lớn nhất thế giới thuộc thành phố nào?",
        options: ["New York", "London", "Tokyo", "Moscow"],
        correctAnswer: "London"
    }
];

const prizes = [
    { question: 1, value: '500.000' }, { question: 2, value: '1.000.000' },
    { question: 3, value: '2.000.000' }, { question: 4, value: '3.000.000' },
    { question: 5, value: '5.000.000' }, { question: 6, value: '7.000.000' },
    { question: 7, value: '10.000.000' }, { question: 8, value: '12.000.000' },
    { question: 9, value: '15.000.000' }, { question: 10, value: '20.000.000' },
    { question: 11, value: '40.000.000' }, { question: 12, value: '60.000.000' },
    { question: 13, value: '80.000.000' }, { question: 14, value: '100.000.000' },
    { question: 15, value: '150.000.000' }
];

export { questions, prizes };