import React, { useState, useEffect } from 'react';
import { Card, Button, message, Modal } from 'antd';
import { questions } from '../config/data';
import './question.css';
import Sidebar from './sideBar';

const Question = ({ setStart }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [randomQuestions, setRandomQuestions] = useState([]);

    const getRandomQuestions = () => {
        return questions
            .sort(() => Math.random() - 0.5) // Trộn ngẫu nhiên danh sách câu hỏi
            .slice(0, 15); // Lấy 15 câu đầu tiên
    };

    useEffect(() => {
        setRandomQuestions(getRandomQuestions()); // Lấy câu hỏi khi component mount
    }, []);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleSubmit = () => {
        if (!selectedOption) {
            message.error('Vui lòng chọn đáp án trước!.');
            return;
        }

        const currentQuestion = questions[currentQuestionIndex];

        if (currentQuestion.correctAnswer === selectedOption) {
            message.success('Chính xác!');
            setIsSubmitted(true);
            setCorrectAnswers(correctAnswers + 1);
        } else {
            // setIsModalVisible(true);
            Modal.info({
                title: 'Sai rồi! Trò chơi kết thúc!',
                content: (
                    <div>
                        <p>Bạn đã trả lời đúng {correctAnswers} câu.</p>
                        <p>Cảm ơn bạn đã tham gia trò chơi!</p>
                    </div>
                ),
                onOk() {
                    setStart(false);
                }
            });
        }


    };

    const handleNextQuestion = () => {
        setIsSubmitted(false);
        setSelectedOption(null);

        if (currentQuestionIndex < randomQuestions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            Modal.info({
                title: 'Chúc mừng bạn!',
                content: (
                    <div>
                        <p>Bạn đã trở thành triệu phú!</p>
                        <p>Phần thưởng của bạn là 150 triệu!</p>
                        <p>Phần thưởng sẽ được chuyển đến bạn trong đêm nay!!! (Phải ngủ mới nhận được @@)</p>
                    </div>
                ),
                onOk() {
                    setStart(false);
                }
            });
        }
    };

    const currentQuestion = randomQuestions[currentQuestionIndex];

    return (
        <div style={{ display: 'flex' }}>
            <Card style={{ width: 700 }}>
                <div className="card-title">Câu hỏi {currentQuestionIndex + 1}</div>
                <div className="question-container">

                    <div className="question-text">
                        {currentQuestion?.question}
                    </div>
                    <div className="options-grid">
                        {currentQuestion?.options.map((option, index) => (
                            <div
                                key={option}
                                className="option-item"
                                style={{
                                    backgroundColor: selectedOption === option ? 'orange' : 'blue',
                                    color: selectedOption === option ? 'black' : 'white',
                                    //padding: '10px',
                                    paddingLeft: '20px',
                                    borderRadius: '20px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleOptionClick(option)}
                            >
                                {String.fromCharCode(65 + index)}. {option} {/* Hiển thị ABCD */}
                            </div>
                        ))}
                    </div>

                </div>
                {!isSubmitted && (
                    <Button type="primary" onClick={handleSubmit} className="submit-btn">Đây là câu trả lời cuối cùng của tôi!</Button>
                )}
                {isSubmitted && (
                    <Button type="primary" onClick={handleNextQuestion} className="next-btn">Câu tiếp theo</Button>
                )}

            </Card>
            <Sidebar currentQuestionIndex={currentQuestionIndex} />
        </div>
    );
};

export default Question;
