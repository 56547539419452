import React, { useState } from 'react';
import './Altp.css';
import Question from './components/question';

const Altp = () => {
    const [start, setStart] = useState(false);
    const handleStart = () => {
        setStart(true)
    };
    return (
        <div className="container">
            {
                start ?
                    <Question setStart={setStart} />
                    :
                    <div
                        className='start'
                        onClick={() => handleStart()}
                    >
                        Bắt Đầu
                    </div>
            }

        </div>
    );
};

export default Altp;
